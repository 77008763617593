export default {
  computed: {
    isAutomation() {
      return this.$route.query.automation === 'true' && location.hostname === 'dev.eown.io'
    },
    hasRecaptcha() {
      return this.$config.recaptcha.siteKey !== 'not-implement' && !this.isAutomation
    }
  },

  async mounted() {
    if (this.hasRecaptcha) {
      try {
        await this.$recaptcha.init()
      } catch (e) {}
    }
  },

  beforeDestroy() {
    if (this.hasRecaptcha) {
      this.$recaptcha.destroy()
    }
  },

  methods: {
    async getRecaptchaToken(type) {
      if (this.hasRecaptcha) {
        const token = await this.$recaptcha.execute(type)
        return token
      }
      if (this.isAutomation) {
        return 'automation'
      }
      return undefined
    },
    async resetRecaptcha() {
      if (this.hasRecaptcha) {
        await this.$recaptcha.reset()
      }
    }
  }
}
